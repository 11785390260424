import configData from "../config.json";

export function fetchAll(callback) {
  let apiUrl = configData.BACKEND_URL;
  let urls = [
    "intro",
    "homepage",
    "tours",
    "stations",
    "locations",
    "backgroundsounds",
    "mitwirkende",
    "imprint",
    "datenschutz",
    "stationswechsel",
    "menu"
  ];
  let result = {};

  Promise.all(
    urls.map((url) =>
      fetch(apiUrl +"/" + url)
        .then(checkStatus)
        .then(parseJSON)
        .then((data) => (result[url] = data))
        .catch(() => {
          console.log("There is a problem fetching:", url)
        })
    )
  ).then((e) => callback(result));

  function checkStatus(response) {
    if (response.ok) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }

  function parseJSON(response) {
    return response.json();
  }
}
