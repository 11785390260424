import configData from "../../config.json";
import { h } from "preact";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  SVGOverlay,
  GeoJSON,
  Popup,
} from "react-leaflet";
import "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css"; // Re-uses images from ~leaflet package
import "leaflet-defaulticon-compatibility";
import { useEffect, useState } from "preact/hooks";
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css";
import { Link } from "preact-router";
import "../../style/bigmap.css";
import "../../style/map.css";

import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import Content from "../../components/Content";
import MarkerClusterGroup from "react-leaflet-markercluster";

var url =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='2' viewBox='0 0 22 31' id='icon-location' version='1.1'%3E%3Cpath d='M10.864 30.59l-.582-.712C9.894 29.36 0 17.785 0 10.865 0 4.851 4.915.001 10.864.001c5.95 0 10.865 4.914 10.865 10.864 0 6.92-9.895 18.56-10.283 19.013l-.582.711z' fill-rule='nonzero' id='path2' style='fill:%23374a86' /%3E%3Cpath d='M10.864 15.004c-2.263 0-4.139-1.876-4.139-4.139 0-2.263 1.876-4.139 4.14-4.139 2.263 0 4.138 1.876 4.138 4.139 0 2.263-1.875 4.139-4.139 4.139zm0-6.726a2.594 2.594 0 00-2.587 2.587 2.594 2.594 0 002.587 2.587 2.594 2.594 0 002.587-2.587 2.594 2.594 0 00-2.587-2.587z' fill-rule='nonzero' id='path4' style='fill:%23ffffff' /%3E%3C/svg%3E";

const iconMarker = new L.Icon({
  iconUrl: url,
  iconRetinaUrl: url,
  iconAnchor: [20, 50],
  popupAnchor: [0, -50],
  shadowAnchor: [40, 50],
  iconSize: new L.Point(40, 56),
});

var svgElementBounds = [
  [50.94155250461109, 11.275684833526611],
  [50.93676929167634, 11.281124353408813],
];
const Fly = (props) => {
  const map = useMap();

  if (props.stations && props.stations.length != 0 ) {
    let markers = props.stations
    .filter(station => station?.location?.marker) 
    .map(station => 
      L.marker(station.location.marker) 
    );
    if(markers.length){
      let markersGroup = L.featureGroup(markers);
      map.flyToBounds(markersGroup.getBounds());
    }
  }
  props.setAction(false);
  return null;
};

const Locate = (props) => {
  const map = useMap();

  if (props.locate) {
    L.control.locate({ cacheLocation: true }).addTo(map);
    props.setLocate(false);
  }
  return null;
};

export const MapBig = (props) => {
  let [locate, setLocate] = useState(true);
  let [action, setAction] = useState(true);

  useEffect(() => {
    setAction(true);
  }, [props.station]);
  return (
    <aside style={{ overflow: "unset" }} class="map">
      <div class="sticky">
        <MapContainer
          style={{ height: window.innerHeight }}
          scrollWheelZoom={false}
          center={[50.9397711110769, 11.278152465820312]}
          zoom={14}
          maxZoom={40}
          tap={false}
        >
          {false && (
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          )}
          {true && (
            <SVGOverlay bounds={svgElementBounds}>
              <svg>
                <use xlinkHref="#mapmap"></use>
              </svg>
            </SVGOverlay>
          )}
          <MarkerClusterGroup maxClusterRadius={45}>
            {props.stations &&
              props.stations.map((station, i) => {
                //Recolor Icons
                let newUrl = url;
                if (props.tours) {
                  let tour = props.tours.find((tour) =>
                    tour.stationsSorted.find(
                      (stationSorted) => stationSorted.station.id == station.id
                    )
                  );
                  let colorCode = tour?.color;
                  let colorCodeNoHash = colorCode?.replace("#", "");
                  newUrl = url.replace("374a86", colorCodeNoHash);
                }
                let iconMarkerColored = new L.Icon({
                  iconUrl: newUrl,
                  iconRetinaUrl: newUrl,
                  iconAnchor: [20, 50],
                  popupAnchor: [0, -50],
                  shadowAnchor: [40, 50],
                  iconSize: new L.Point(40, 50),
                });
                return (
                  <>
                  {station?.location?.marker && <Marker
                    position={station?.location?.marker}
                    icon={iconMarkerColored}
                  >
                    <Popup>
                      <Link
                        href={
                          "/tour/" +
                          props.tours.find(
                            (tour) =>
                              tour.stationsSorted.find(
                                (s) => s.station.id == station.id
                              ) && tour.id
                          ).id +
                          "/station/" +
                          station.id
                        }
                      >
                        {false && (
                          <img
                            style={{ width: 320 }}
                            src={
                              configData.BACKEND_URL +
                              station.thumbnailImage.url
                            }
                          />
                        )}
                        <p>{station.name_external}</p>
                      </Link>
                    </Popup>
                  </Marker>}
                  </>
                );
              })}
          </MarkerClusterGroup>
          <Fly {...{ ...props, action, setAction }} />
          <Locate {...{ locate, setLocate }} />
        </MapContainer>
      </div>
    </aside>
  );
};
