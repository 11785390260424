import "./style";
import App from "./components/app";
import configData from "./config.json";

import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const AppComp = () => {
  if (configData.TRACKING.IS_ENABLED) {
    const instance = createInstance({
      urlBase: configData.TRACKING.BACKEND_URL,
      siteId: configData.TRACKING.SITE_ID,
      //linkTracking: false, // Important!
      configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: configData.TRACKING.IS_DISABLED_COOKIES,
      },
    });
    return (
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    );
  }
  else {
    // No Matomo wrapper, for no tracking integration
    return  <App />
  }
}

  export default AppComp;
